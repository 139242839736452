import Vue from 'vue'
import {mapMutations} from 'vuex'

Vue.component('ProfileHero', {
  data: () => ({
    isExpandedServices: false,
    collapsedHeight: ''
  }),
  watch: {
    expandAccordion (isOpened) {
      this.isExpandedServices = isOpened
    },
    isExpandedServices: function (expandNode) {
      const el = this.$refs.servicesPanel
      const sectionHeight = el.scrollHeight;

      (expandNode)
        ? this.expandContent(sectionHeight)
        : this.collapseContent(sectionHeight)

      this.removeListener(el, expandNode)
    }
  },
  methods: {
    ...mapMutations({
      isVCardShowing: 'vCardModal/isVCardShowing',
      vCardLink: 'vCardModal/vCardLink',
      isShowing: 'emailForm/isShowing',
      id: 'emailForm/id'
    }),    
    showEmailForm (id) {
      this.isShowing(true)
      this.id(id)
    },
    showVCardModal (vCardLink) {
      this.isVCardShowing(true)
      this.vCardLink(vCardLink)
    },  
    toggleServices () {
      this.isExpandedServices = !this.isExpandedServices
    },
    handleLastChildTabEvent () {
      const currentActive = document.activeElement
      const panel = this.$refs.servicesPanel

      console.log(currentActive)

      console.log(panel.contains(currentActive))
      if (!panel.contains(currentActive)) {
        this.isExpandedServices = false
      }
    },
    handleFocusOut () {
      this.$nextTick(() => {
        const currentActive = document.activeElement
        const panel = this.$refs.servicesPanel

        if (!panel.contains(currentActive) && (currentActive !== this.$refs.serviceToggle)) {
          this.isExpandedServices = false
        }
      })
    },
    expandContent (heightToExpand) {
      window.requestAnimationFrame(() => {
        this.collapsedHeight = heightToExpand + 'px'
      })
    },
    collapseContent (currentHeight) {
      window.requestAnimationFrame(() => {
        this.collapsedHeight = currentHeight + 'px'

        window.requestAnimationFrame(() => {
          this.collapsedHeight = 0 + 'px'
        })
      })
    },
    removeListener (element, expandState) {
      var expandedState = expandState

      element.addEventListener('transitionend', () => {
        // remove this event listener so it only gets triggered once
        element.removeEventListener('transitionend', void (0))

        Vue.nextTick(() => {
          if (expandedState) {
            // remove "height" from the element's inline styles, so it can return to its initial value
            this.collapsedHeight = 'auto'
          } else {
            this.collapsedHeight = 0 + 'px'
          }
        })
      })
    }
  }
})
