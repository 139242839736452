import Vue from 'vue'
import { shuffle, range } from './../utils/helpers'

Vue.component('Carousel', {
  props: {
    randomize: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isMounted: true,
    images: [],
    slides: [],
    activeIndex: 0,
    beltHeight: 'inherit',
    slideRange: []

  }),
  mounted () {
    let arrRange = range(this.slides.length)

    this.slideRange = (this.randomize)
      ? shuffle(arrRange)
      : arrRange
  },
  computed: {
    slidesLength () {
      return this.slides.length - 1
    },
    labelTotalSlides () {
      return this.slides.length
    },
    labelCurrentSlide () {
      return this.activeIndex + 1
    },
    activeSlide () {
      return this.slideRange[this.activeIndex]
    },
    displayArrows () {
      return this.slides.length > 1;
    }
  },
  watch: {
    slides (newVal) {
      this.$nextTick(() => {
        this.computeBeltHeight()
      })
    }
  },
  methods: {
    addSlide ({imgUrl, title}) {
      let componentId

      this.images.push(imgUrl)
      componentId = this.slides.push({
        imgUrl,
        title
      })

      return componentId - 1
    },
    onNextSlide () {
      this.activeIndex = (this.activeIndex + 1) % this.slides.length
    },
    onPrevSlide () {
      this.activeIndex = (this.activeIndex - 1 < 0)
        ? this.slidesLength
        : this.activeIndex - 1
    },
    computeBeltHeight (minHeight) {
      this.beltHeight = minHeight + 'px'
    },
    isActiveImage (givenIndex) {
      return givenIndex === this.activeSlide
    }
  },
  components: {
    'CarouselSlide': {
      data: () => ({
        myId: 0
      }),
      props: ['currentActive', 'setupSlide', 'imgUrl', 'title', 'computeHeightCallback'],
      mounted () {
        this.myId = this.setupSlide({
          imgUrl: this.imgUrl,
          title: this.title
        })
      },
      computed: {
        isActive () {
          return this.myId === this.currentActive
        }
      },
      watch: {
        currentActive (newVal) {
          if (this.isActive) {
            this.$nextTick(() => {
              this.computeHeightCallback(this.$el.clientHeight + 20)
            })
          }
        }
      }
    }
  }
})
