// REQUIRED ----> Vue Setup: Do not edit
import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Store from './store'
import './directives'
import { isNumber, isNil } from './utils/helpers'
// // ____________________________________________________________________________________________
import Router from 'vue-router'
import {TweenMax} from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

// Features (as components): Page parts instances
import StickyHeader from './components/stickyheader'
import StickySidebar from './components/stickysidebar'
import PageSection from './components/pagesection'
import PageNav from './components/pagenav'
import Accordion from './components/accordion'

// // Global Components : To be accessible by all Vue components and instances
import ScrollTrigger from './components/scrolltrigger'
import VanillaStickyHeader from './vanilla/stickyheader'
import BackgroundVideo from './vanilla/backgroundvideo'
import CoveoScroll from './vanilla/coveoscroll'

require('./polyfills')
require('./components')

const store = Store(Vue)

const router = new Router({
  mode: 'history'
})

Vue.component('scrolltrigger', ScrollTrigger())
Vue.component('pagenav', PageNav())
Vue.component('stickysidebar', StickySidebar())
Vue.component('accordion', Accordion())

Vue.use(Router)
// Vue.use(VueScrollTo, {
//   duration: 600,
//   offset: -120
// })
const addScriptToPage = function (scriptURL, callback) {
  let scriptTag = document.createElement('script')

  scriptTag.src = scriptURL

  let firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag)

  if (scriptTag.readyState) {  //IE
    scriptTag.onreadystatechange = function () {
      if (scriptTag.readyState === 'loaded' || scriptTag.readyState === 'complete') {
        scriptTag.onreadystatechange = null
        callback()
      }
    }
  } else {
    scriptTag.onload = function () {
      callback()
    }
  }
}

let ariaTabbableleSelector = '.page a, .page button, .page input, .page select, .page textarea, .footer a, .header .nav a, .page [tabindex]'

const DELAY_UNTIL_HOMEPAGE_LINE_ANIMATES = 300

// // Bind Vue to page element
const createApp = function (elementId, appName) {
  window[appName] = new Vue({
    store,
    router,
    el: elementId,
    data: {
      lastFocusedElement: '',
      ariaContentDisabled: false,
      isBackgroundVideoPlaying: true,
      readyToAnimate: false,
      showVideoModal: false
    },
    components: {
      // Page parts instances
      'stickyheader': StickyHeader(),
      'pagesection': PageSection()
    },
    computed: {
      pagesection () {
        return this.$route.query['section']
      },
      panelactive () {
        return this.menuactive || this.searchactive
      },
      scrolloffset () {
        return this.headerheight
      },
      ...mapGetters({
        windowposition: 'viewport/positionY',
        mobile: 'viewport/mobile',
        menuactive: 'menupanel/active',
        searchactive: 'searchpanel/active',
        haspagenav: 'pagenav/hasnav',
        headerheight: 'header/height',
        shareactive: 'sharemenu/active',
        keywordSearch: 'searchpanel/keyword',
        videoUrl: 'videoPlayer/videoUrl',
        vimeoApiLoaded: 'videoPlayer/vimeoApiLoaded',
        youtubeApiLoaded: 'videoPlayer/youtubeApiLoaded'        
      })
    },
    watch: {
      '$route' (next, last) {
        let nextSection = next.query['section']
  
        if (nextSection) {
          this.gotToSection()
        }
      },
      panelactive (newState, oldState) {
        (newState)
          ? this.$nextTick(() => { this.ariaDisableContent() })
          : this.$nextTick(() => { this.ariaEnableContent() })
      }
    },
    methods: {
      ...mapMutations({
        updateKeyword: 'searchpanel/updateKeyword'
      }),
      ...mapActions({
        setVimeoApiLoaded: 'videoPlayer/setVimeoApiLoaded',
        setYoutubeApiLoaded: 'videoPlayer/setYoutubeApiLoaded',        
        setVideoUrl: 'videoPlayer/setVideoUrl'
      }),
      onKeyPress (evt) {
        evt = evt || window.event
        let key = evt.keyCode
  
        this.keyPress = key
      },
      closeMenuOrSearch () {
        if (this.searchactive) this.closeSearchAndHandleFocus()
        if (this.menuactive) this.closeMenuAndHandleFocus()
      },
      toggleMenu (evt) {
        window.header.toggleOffSearchView()
        if (this.menuactive) {
          this.closeMenuAndHandleFocus()
        } else {
          this.getFocusedElementBeforeDialogOpened()
          this.openMenu()
          
          if (this.searchactive) this.closeSearch()
        }
      },
      openMenu () {
        this.$store.dispatch('menupanel/toggle', true)
        bodyScrollLock.disableBodyScroll(document.querySelector('.fullpanel__inner'))
      },
      closeMenu () {
        this.$store.dispatch('menupanel/toggle', false)
        bodyScrollLock.enableBodyScroll(document.querySelector('.fullpanel__inner'))
      },
      closeMenuAndHandleFocus () {
        this.closeMenu()
  
        this.$nextTick(() => {
          if (!this.searchactive) {
            this.placeFocusOnElementBeforeDialogOpened()
          }
        })
      },
      toggleSearch () { 
          this.$store.dispatch('searchpanel/toggle')
          this.getFocusedElementBeforeDialogOpened()
  
          if (this.menuactive) {
            this.closeMenu()
          }

          const siteSearchInputField = document.querySelector('.sitesearch .magic-box-input input')

          // test for iOS device. iOS devices won't allow autofocus to work, especially with a setTimeout so we have to disable it for these devices
          // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios for more info
          const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

          if (siteSearchInputField && !this.menuactive && !iOS) {
            setTimeout(() => siteSearchInputField.focus(), 100)
          }

          window.header.toggleSearchView()
      },
      closeSearch () {
        this.$store.dispatch('searchpanel/toggle', false)
      },
      closeSearchAndHandleFocus () {
        this.closeSearch()
  
        this.$nextTick(() => {
          if (!this.menuactive) {
            this.placeFocusOnElementBeforeDialogOpened()
          }
        })
      },
      getElementHeight (element) {
        let height = (element) ? Math.max(
          element.offsetHeight,
          element.clientHeight
        ) : null
        return height
      },
      getElementOffset (element) {
        if (!element) return {}
        let de = document.documentElement
        let box = element.getBoundingClientRect()
        let top = box.top + window.pageYOffset - de.clientTop
        let bottom = box.bottom + window.pageYOffset - de.clientTop
        let left = box.left + window.pageXOffset - de.clientLeft
        return { top: top, bottom: bottom, left: left }
      },
      elementInView (element, offset) {
        offset = isNumber(offset) ? offset : 0
        let box = this.getElementOffset(element)
        let is = (box.top > -1 && box.bottom + offset <= window.innerHeight)
        return is
      },
      elementAboveFold (element, offset) {
        offset = isNumber(offset) ? offset : 0
        let box = this.getElementOffset(element)
        let is = (box.top + offset <= window.innerHeight)
        return is
      },
      updateSectionHistory (id) {
        this.$router.push({path: this.$route.path, query: { section: id }})
      },
      gotToSection (where, offset) {
        if (this.pagesection && this.haspagenav) {
          this.$store.dispatch('pagenav/activate', this.pagesection)
        }
      },
      documentReady (callback) {
        if (document.readyState !== 'loading') { // in case the document is already rendered
          callback()
        } else {
          document.addEventListener('DOMContentLoaded', callback)
        }
      },
      speed: function (newY, oldY) {
        const rate = 2
        const ppr = 60 // pixels per rate
        const winY = oldY
  
        const distance = (newY > winY) ? ((newY - winY)) : ((winY - newY))
        const velocity = (rate / ppr) / 100
        const speed = this.round(distance * velocity)
        return speed
      },
      round: function (v, d) {
        d = (isNil(d)) ? 2 : d
        return Number(Math.round(v + 'e' + d) + 'e-' + d)
      },
      scrollToAndFocus (element) {
        this.scrollTo(element, (element) => { this.focusOnElement(element) })
      },
      scrollTo (element, callback) {
        let newY = this.getElementOffset(element).top - this.scrolloffset - 32
        let speed = (this.mobile) ? 0 : this.speed(newY, this.windowposition)
  
        TweenMax.to(
          window,
          speed,
          {
            scrollTo: { y: newY, autoKill: false },
            onComplete: () => (callback) ? callback(element) : false
          }
        )
      },
      focusOnElement (element) {
        const scrollYBeforeFocus = window.pageYOffset
        element.focus()
        window.scroll(0, scrollYBeforeFocus)
      },
      nthClass (styleclass, index, divider) {
        // provide class iteration based on item index and max quantity allowed
  
        // if (arguments.length < 3) {
        //   throw new Error('nthClass needs 3 parameters')
        // }
  
        let indexup = (index + 1)
        let greater = indexup > divider
        let level = Math.floor(index / divider) + 1
        let calc = (indexup - (divider * level)) + divider
  
        let newindex = Math.floor((greater) ? calc : indexup)
  
        let newclass = styleclass + 'ready ' + styleclass + '' + newindex
  
        return newclass
      },
      mailPage (subject) {
        const title = document.title
        const newline = '%0A'
        const body = encodeURIComponent(document.location.href)
  
        subject = encodeURIComponent(subject)
  
        window.open('mailto:?subject=' + subject + '&body=' + body,'_self')
      },
      printPage () {
        window.print()
      },
      toggleShare () {
        this.$store.dispatch('sharemenu/toggle')
      },
      performSearch () {
        if (this.keywordSearch.length > 0) {
          window.location = `/search?query=${encodeURIComponent(this.keywordSearch)}`
        }
      },
      goToUrl (url) {
        if (!!url) {
          window.location = url
        }
      },
      getFocusedElementBeforeDialogOpened () {
        this.lastFocusedElement = document.activeElement
      },
      placeFocusOnElementBeforeDialogOpened () {
        if (this.lastFocusedElement !== '') {
          this.lastFocusedElement.focus()
          this.lastFocusedElement = ''
        }
      },
      ariaDisableContent () {
        let contentToDisableHTMLNodes = document.querySelectorAll(ariaTabbableleSelector)
        let arrayContentToDisable = Array.prototype.slice.call(contentToDisableHTMLNodes)
  
        arrayContentToDisable.forEach((element) => {
          if (element.hasAttribute('has-tabindex') || element.getAttribute('tabindex') === '0') {
            element.setAttribute('has-tabindex', true)
          }
          element.setAttribute('tabindex', '-1')
        })
  
        this.ariaContentDisabled = true
        arrayContentToDisable = undefined
      },
      ariaEnableContent () {
        let contentToDisableHTMLNodes = document.querySelectorAll(ariaTabbableleSelector)
        let arrayContentToDisable = Array.prototype.slice.call(contentToDisableHTMLNodes)
  
        arrayContentToDisable.forEach((element) => {
          if (element.hasAttribute('tabindex') && element.getAttribute('tabindex') !== '0') {
            element.setAttribute('tabindex', '0')
          } else {
            element.removeAttribute('tabindex')
          }
        })
        this.ariaContentDisabled = false
        arrayContentToDisable = undefined
      },
      showVimeoModal(){
        this.showVideoModal = true
        document.getElementById('vimeo-player').style.display = ''
        document.getElementById('youtube-player').style.display = 'none'
      },
      showYoutubeModal(){
        this.showVideoModal = true
        document.getElementById('vimeo-player').style.display = 'none'
        document.getElementById('youtube-player').style.display = ''
      },      
      setVimeoEventListener () {
        this.vimeoPlayer.ready().then(() => {
          this.vimeoPlayer.play()
          this.showVimeoModal()
        })
      },
      setYoutubeEventListener () {
      },      
      loadVimeoApiAndPlayVideo (videoUrl) {
        addScriptToPage('//player.vimeo.com/api/player.js', () => {
          this.setVideoUrl(videoUrl)
          this.vimeoPlayer = new window.Vimeo.Player('vimeo-player', {
            width: 640,
            autoplay: true,
            playsinline: true,
            id: videoUrl
          })
          this.setVimeoEventListener()
        })
  
        this.setVimeoApiLoaded(true);
        bodyScrollLock.disableBodyScroll(document.querySelector('.video-modal'));
      },
      loadYoutubeApiAndPlayVideo (videoUrl) {
        window.onYouTubeIframeAPIReady = () => {
          this.setVideoUrl(videoUrl)
          const _ = this;
          this.youtubePlayer = new YT.Player('youtube-player', {
            width: 640,
            videoId: videoUrl,
            playerVars: {'wmode': 'opaque', 'autohide': 1 , 'enablejsapi': 1 , 'origin': window.location.origin, 'rel': 0},
            events: {
              onReady: _.onYoutubePlayerReady
            }
          });
          //this.setYoutubeEventListener()
          this.setYoutubeApiLoaded(true);
          bodyScrollLock.disableBodyScroll(document.querySelector('.video-modal'));  
        };
        addScriptToPage('//www.youtube.com/iframe_api', ()=>{});  
      },   
      onYoutubePlayerReady() {
        this.youtubePlayer.playVideo()
        this.showYoutubeModal()
      },   
      loadVimeoVideo (videoUrl) {
        this.setVideoUrl(videoUrl)
        this.vimeoPlayer.loadVideo(videoUrl)
          .then(() => {
            this.vimeoPlayer.play()
            this.showVimeoModal();
            bodyScrollLock.disableBodyScroll(document.querySelector('.video-modal'));
          })
      },
      loadYoutubeVideo (videoUrl) {
        this.setVideoUrl(videoUrl)
        this.youtubePlayer.loadVideoById(videoUrl)
        this.showYoutubeModal();
        bodyScrollLock.disableBodyScroll(document.querySelector('.video-modal'));
      },      
      playVideo (videoUrl) {        
        if (videoUrl.indexOf('vimeo') > -1){
          const splitVideoUrl = videoUrl.split('/')
          const videoID = splitVideoUrl[splitVideoUrl.length-1]
          if (this.videoUrl === videoID) {
            this.showVimeoModal()
            this.vimeoPlayer.play()
            return
          }
          if (this.vimeoApiLoaded) {
            this.loadVimeoVideo(videoID)
          } else {
            this.loadVimeoApiAndPlayVideo(videoID)
          }
        }else{          
          const videoID = videoUrl
          if (this.videoUrl === videoID) {
            this.showYoutubeModal()
            this.youtubePlayer.playVideo()
            return
          }
          if (this.youtubeApiLoaded) {
            this.loadYoutubeVideo(videoID)
          } else {
            this.loadYoutubeApiAndPlayVideo(videoID)
          }
        }
      },
      closeVideo () {
        this.showVideoModal = false
        this.vimeoPlayer && this.vimeoPlayer.pause()
        this.youtubePlayer && this.youtubePlayer.pauseVideo()
        bodyScrollLock.enableBodyScroll(document.querySelector('.video-modal'));
      },
      toggleBackgroudVideoPlay () {
        this.isBackgroundVideoPlaying = !this.isBackgroundVideoPlaying;
  
        (this.isBackgroundVideoPlaying)
          ? this.$refs.backgroundvideo.play()
          : this.$refs.backgroundvideo.pause()
      }
    },
    mounted () {
      document.onkeydown = (evt) => this.onKeyPress(evt)
      this.gotToSection()
      setTimeout(() => {
        this.readyToAnimate = true
      }, DELAY_UNTIL_HOMEPAGE_LINE_ANIMATES)
    }
  })  
}

if (document.getElementById('vueapp')) {
  createApp('#vueapp', 'App')
}

if (document.getElementById('vueheaderapp')) {
  createApp('#vueheaderapp', 'HeaderApp')
}

if (document.getElementById('vuefooterapp')) {
  createApp('#vuefooterapp', 'FooterApp')
}

document.addEventListener('afterInitialization', function () {
  const siteSearchInputField = document.querySelector('.sitesearch .magic-box-input input')
  if (!!siteSearchInputField) {        
      siteSearchInputField.addEventListener('keydown', function (e) {
          var key = e.keyCode || e.which
          if (key === 13) {
              var searchValue = siteSearchInputField.value
              if (searchValue && searchValue.length > 0) {
                  var searchValue = encodeURIComponent(searchValue)
                  window.location.href = "/site-search#q=" + searchValue
              }
          }
      })
  }
})

window.header = {
  searchDisplayed: false,
  toggleOffSearchView: function () {
    let body = document.getElementById('default')
    let header = document.getElementById('site-nav-header')
    let searchPanel = document.getElementById('searchFullPanel')

    body.classList.remove('body__inner--panelactive')
    header.classList.remove('header--searchactive')
    searchFullPanel.classList.remove('fullpanel--active')

    this.searchDisplayed = false
  },
  toggleSearchView: function () {
    let body = document.getElementById('default')
    let header = document.getElementById('site-nav-header')
    let searchPanel = document.getElementById('searchFullPanel')

    body.classList.toggle('body__inner--panelactive')
    header.classList.toggle('header--searchactive')
    searchFullPanel.classList.toggle('fullpanel--active')

    this.searchDisplayed = !this.searchDisplayed

    if (this.searchDisplayed)
      bodyScrollLock.disableBodyScroll(document.querySelector('#searchFullPanel'))
    else
      bodyScrollLock.enableBodyScroll(document.querySelector('#searchFullPanel'))

  }
}

VanillaStickyHeader()
BackgroundVideo()
CoveoScroll()