import {isUndefined} from './../../../utils/helpers'

export default {
  create: ({commit, state, getters}, payload) => {
    return new Promise((resolve, reject) => {
      let item = Object.assign(
        {
          id: state.items.length
        }, payload)
      commit('create', item)
      resolve(item.id)
    })
  },
  createTrigger: ({commit, state, getters}, payload) => {
    return new Promise((resolve, reject) => {
      let item = Object.assign(
        {
          id: state.triggers.length
        }, payload)
      commit('createTrigger', item)
      resolve(item.id)
    })
  },
  activate: ({commit, state, getters}, id) => {
    return new Promise((resolve, reject) => {
      commit('activate', id)
      resolve(state.active)
    })
  },
  isactive: ({state}, id) => {
    return new Promise((resolve, reject) => {
      let exists = !isUndefined(state.actives.find(item => item === id))
      resolve(exists)
    })
  }
}
