import { TweenLite } from 'gsap'
import 'gsap/ScrollToPlugin'

export default {
  props: {
    'autoScrollEnable': {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    isExpanded: false,
    collapsedHeight: '',
    scrollTime: 0.5
  }),
  watch: {
    isExpanded: function (expandNode) {
      const el = this.$refs.collapsedPanel
      const sectionHeight = el.scrollHeight;

      (expandNode)
        ? this.expandContent(sectionHeight)
        : this.collapseContent(sectionHeight)

      this.removeListener(el, expandNode)
    }
  },
  methods: {
    viewMoreExpanded () {
      return this.isExpanded
    },
    toggleServices () {
      this.isExpanded = !this.isExpanded
    },
    expandContent (heightToExpand) {
      window.requestAnimationFrame(() => {
        this.collapsedHeight = heightToExpand + 'px'
      })

      // this.$nextTick(() => { this.$refs.collapsedPanel.focus() })
    },
    collapseContent (currentHeight) {
      window.requestAnimationFrame(() => {
        this.collapsedHeight = currentHeight + 'px'

        window.requestAnimationFrame(() => {
          this.collapsedHeight = 0 + 'px'
        })
      })

      if (this.autoScrollEnable) {
        TweenLite.to(window, this.scrollTime, {
          scrollTo: { y: this.$refs.collapsedPanel, offsetY: 100 }
        })
      }
    },
    removeListener (element, expandState) {
      var expandedState = expandState

      element.addEventListener('transitionend', () => {
        // remove this event listener so it only gets triggered once
        element.removeEventListener('transitionend', void (0))

        this.$nextTick(() => {
          if (expandedState) {
            // remove "height" from the element's inline styles, so it can return to its initial value
            this.collapsedHeight = 'auto'
          } else {
            this.collapsedHeight = 0 + 'px'
          }
        })
      })
    }
  }  
}
