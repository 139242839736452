import {isNil} from './../../../utils/helpers'

export default {
  create: ({commit, dispatch, state}, payload) => {
    return new Promise((resolve, reject) => {
      let key = state.items.length
      let item = Object.assign(
        {
          key: key,
          id: (!isNil(payload.id)) ? payload.id : key
        }, payload)
      commit('create', item)
      if (key === 0 && state.items.findIndex((item) => item.active === state.active) === -1) dispatch('activate', item.id)
      resolve(item.id)
    })
  },
  activate: ({commit, state}, id) => {
    return new Promise((resolve, reject) => {
      commit('activate', id)
      resolve(id)
    })
  }
}
