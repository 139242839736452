export default {
  setVideoUrl: ({commit, state}, value) => {
    return new Promise((resolve, reject) => {
      commit('videoUrl', value)
      resolve(state.videoUrl)
    })
  },
  setVimeoApiLoaded: ({ commit, state }, value) => {
    return new Promise((resolve, reject) => {
      commit('vimeoApiLoaded', value)
      resolve(state.vimeoApiLoaded)
    })
  },
  setYoutubeApiLoaded: ({ commit, state }, value) => {
    return new Promise((resolve, reject) => {
      commit('youtubeApiLoaded', value)
      resolve(state.youtubeApiLoaded)
    })
  }  
}
