import Vue from 'vue'

let mediumClassTransform = (currentCount) => {
  if (currentCount <= 2) {
    if (currentCount % 4 <= 1) {
      return 'tablet-tile--black'
    } else {
      return 'tablet-tile--white'
    }
  } else {
    if (currentCount % 2 == 0) {
      return 'tablet-tile--black'
    } else {
      return 'tablet-tile--white'
    }
  }
}

let largeClassTransform = (currentCount) => {
  if (currentCount <= 2) {
    if (currentCount % 4 <= 1) {
      return 'desktop-tile--black'
    } else {
      return 'desktop-tile--white'
    }
  } else {
    if (currentCount % 2 == 0) {
      return 'desktop-tile--black'
    } else {
      return 'desktop-tile--white'
    }
  }
}

export default Vue.directive('running-count', {
  bind (el, {value}, vnode) {
    let tileMediumCount = 0
    let tileLargeCount = 0

    if (!value.tileClass && !value.tileClassMedium) {
      throw Error('Need Class Tile Classes Defined')
    }

    let tiles = Array.prototype.slice.call(el.querySelectorAll(`.${value.tileClass}`))

    tiles.forEach((tile, index) => {
      let colorTileMediumCount
      let colorTileLargeCount

      ++tileMediumCount
      ++tileLargeCount

      if (tile.classList.contains('grid-tiles__item--with-image')) {
        ++tileMediumCount

        tileLargeCount += 2
      }

      colorTileMediumCount = (tile.dataset.alignment === 'left')
        ? tileMediumCount - 1
        : tileMediumCount

      if (tile.dataset.alignment === 'left') {
        colorTileLargeCount = (tileLargeCount % 3 === 0)
          ? tileLargeCount - 2
          : tileLargeCount - 1

        if (tileLargeCount % 3 !== 0) {
          tileLargeCount += (3 - (tileLargeCount % 3))
        }
      } else {
        colorTileLargeCount = tileLargeCount
      }
      tile.classList.add(mediumClassTransform(colorTileMediumCount))
      tile.classList.add(largeClassTransform(colorTileLargeCount))
    })
  }
})
