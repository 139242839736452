import { mapGetters } from 'vuex'

export default{
  data: () => ({
    id: 0,
    element: null,
    responder: null,
    startposition: 0,
    triggers: [],
    activeTriggers: []
  }),
  mounted () {
    this.$store.dispatch('sticky/create', {
      title: this.title
    }).then((which) => {
      // returns the 'id' of the newly created panel item
      this.id = which
      this.setElements()
      this.updateOnPosition()
    })
  },
  computed: {
    isactive () {
      let fun = this.$store.getters['sticky/isactive']
      let is = fun(this.id)
      return is
    },
    enter () {
      let pos = (this.scrollposition + this.computedoffset())
      return pos >= (this.position())
    },
    ...mapGetters('viewport', {
      scrollposition: 'positionY',
      viewportdesktop: 'desktop',
      viewportready: 'ready'
    }),
    ...mapGetters('sticky', {
      items: 'items',
      actives: 'actives',
      triggerItems: 'triggers'
    })
  },
  watch: {
    scrollposition () {
      this.updateOnPosition()
      this.updateOnTriggers()
    },
    viewportdesktop (truthy) {
      if (truthy) {
        this.updateOnPosition()
        this.updateOnTriggers()
      }
    }
  },
  methods: {
    setElements () {
      this.element = this.$el
      this.responder = (this.$refs && this.$refs.responder) ? this.$refs.responder.$el : this.$el
    },
    position () {
      return this.$root.getElementOffset(this.element).top
    },
    computedoffset () {
      return 0
    },
    updateOnPosition () {
      if (this.enter) {
        this.$store.commit('sticky/activate', this.id)
      } else {
        this.$store.commit('sticky/deactivate', this.id)
      }
    },
    updateOnTriggers () {
      if (this.isactive) {
        let activeTriggers = []
        this.triggerItems.forEach((item) => {
          if (this.triggers.findIndex((trigger) => trigger.id === item.id)) {
            let itemElement = this.$root.getElementOffset(item.el)
            let element = this.$root.getElementOffset(this.responder)
            if (element.bottom > itemElement.top &&
            element.top < itemElement.bottom) {
              activeTriggers.push(item.id)
            }
          }
        })
        this.activeTriggers = activeTriggers
      } else {
        this.activeTriggers = []
      }
    },
    activeTriggerClass (prefix = '') {
      let activeClass = []
      this.activeTriggers.forEach((item) => {
        activeClass.push(prefix + 'trigger-' + item)
      })
      return activeClass
    }
  }
}
