import Vue from 'vue'

const contains = (query) => (result) =>
  result.title.toLowerCase().indexOf(query.toLowerCase()) !== -1

const apiTypeahead = (query) =>
  Promise.resolve([
    {
      'title': 'Amelie',
      'url': '/insights-detail'
    },
    {
      'title': 'Cleo',
      'url': '/insights-detail'
    },
    {
      'title': 'Ella',
      'url': '/insights-detail'
    },
    {
      'title': 'Pippa',
      'url': '/insights-detail'
    },
    {
      'title': 'Bunsen',
      'url': '/insights-detail'
    },
    {
      'title': 'Burner',
      'url': '/insights-detail'
    },
    {
      'title': 'Test keyword result 1',
      'url': '/insights-detail'
    },
    {
      'title': 'Test keyword result 2',
      'url': '/insights-detail'
    },
    {
      'title': 'Test keyword result 3',
      'url': '/insights-detail'
    }
  ]).then(results => results.filter(contains(query)))

Vue.component('Typeahead', {
  props: ['endpoint'],
  data: () => ({
    query: '',
    options: [],
    focusedIndex: -1 // Not focused on any items
  }),
  computed: {
    hasOptions () {
      return this.options.length > 0
    },
    reachedEndOfOptions () {
      return (this.focusedIndex >= this.options.length)
    },
    reachedSearchBoxFocus () {
      return (this.focusedIndex === -1)
    },
    reachedOutOfBounds () {
      return this.focusedIndex === -2
    }
  },
  methods: {
    redirectPageTo (url) {
      window.location = url
    },
    resetQuery () {
      this.query = ''
      this.options = []
      this.resetFocusedIndex()
    },
    performHit () {
      let queryString = this.query
      if (this.hasOptions && !this.reachedSearchBoxFocus) {
        window.location = this.options[this.focusedIndex].url
      } else if (queryString !== '') {
        this.$emit('searchterm-submit', queryString)
        this.options = []
        this.resetFocusedIndex()
      }
    },
    performQuery () {
      let queryString = this.query

      if (queryString !== '' && queryString.length > 3) {
        return apiTypeahead(queryString)
          .then(results => {
            this.options = results.slice(0, 3)
          })
      } else {
        this.options = []
      }
    },
    isSelected (givenIndex) {
      return (this.focusedIndex === givenIndex)
    },
    resetFocusedIndex () {
      this.focusedIndex = -1
    },
    goDownOneOption () {
      if (this.hasOptions) {
        ++this.focusedIndex

        if (this.reachedEndOfOptions) {
          this.focusedIndex = -1
        }
      }
    },
    goUpOneOption () {
      if (this.hasOptions) {
        --this.focusedIndex

        if (this.reachedOutOfBounds) {
          this.focusedIndex = this.options.length - 1
        }
      }
    }
  }
})
