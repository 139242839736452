import Vue from 'vue'
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'

Vue.component('EmailForm', {
  props: {
    sitekey: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    emailForm: {
      name: '',
      nameText: '',
      nameError: '',
      email: '',
      emailText: '',
      emailError: '',
      message: '',
      messageText: '',
      messageError: '',
      submissionSuccess: false,
      hasSubmitted: false
    },
  }),
  computed: {
    ...mapGetters({
      isShowing: 'emailForm/isShowing',
      id: 'emailForm/id'
    })
  },
  methods: {
    ...mapMutations({
      setShowing: 'emailForm/isShowing'
    }),
    setSubmissionResult(submissionSuccess) {
      this.emailForm.submissionSuccess = submissionSuccess
      this.emailForm.hasSubmitted = true
    },
    close() {
      this.emailForm.name = '';
      this.emailForm.nameError = '';
      this.emailForm.email = '';
      this.emailForm.emailError = '';
      this.emailForm.message = '';
      this.emailForm.messageError = '';
      this.emailForm.hasSubmitted = false;
      this.emailForm.submissionSuccess = false;
      this.setShowing(false);
    },
    validateInput: function () {
      var isValid = true;
      if (this.emailForm.name == "") {
        this.emailForm.nameError = "The field is required."
        isValid = false;
      } else {
        this.emailForm.nameError = "";
      }
      if (this.emailForm.email == "" || !this.emailForm.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        this.emailForm.emailError = "Please enter a valid email.";
        isValid = false;
      } else {
        this.emailForm.emailError = "";
      }
      if (this.emailForm.message == "") {
        this.emailForm.messageError = "The field is required."
        isValid = false;
      } else {
        this.emailForm.messageError = "";
      }
      this.$forceUpdate();
      return isValid;
    },
    submit() {
      if (this.validateInput()) {
        var data = {
          Id: this.id,
          Name: this.emailForm.name,
          Email: this.emailForm.email,
          Message: this.emailForm.message
        };
        let that = this;
        grecaptcha.ready(function () {
          grecaptcha.execute(that.sitekey, {action: 'submit'}).then(function (token) {
            data.RecaptchaToken = token;
            axios.post('/webapi/emails/send', data)
              .then(function (response) {
                if (response.status == 200) {
                  that.setSubmissionResult(true)
                } else {
                  that.setSubmissionResult(false)
                }
              }, function (error) {
                that.setSubmissionResult(false)
              });
          });
        });
      };
    }
  }
})
