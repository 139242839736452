export default {
  template: `
        <div v-on:click.stop="redirectLink" class="contact-card">          
          <figure v-if="person.imgUrl" class="contact-card__figure">
            <img :alt="'Photo of' + person.name" :src="person.imgUrl" class="contact-card__img">
          </figure>
          <div class="contact-card__content">
            <div class="contact-card__name fontsize--h4 fontsize--h4--lg"><a :href="person.url" class="contact-card__link">{{person.name}}</a></div>
            <div class="contact-card__details">
              <span class="contact-card__position">{{person.position}}</span><span v-if="person.officeUrl">,</span>
              <a class="contact-card__office contact-card__link" :href="person.officeUrl">{{person.officeLabel}}</a>
            </div>
            <div class="contact-card__meta">
              <a v-bind:href="'tel:' + person.url" class="contact-card__meta-item contact-card__phone contact-card__link">{{person.phone}}</a>
              <a href="'mailto:' + person.email" class="contact-card__meta-item contact-card__link">{{person.email}}</a>
            </div>
            <div class="contact-card__abstract" v-html="person.abstract"></div>
          </div>
        </div>
      `,
  props: ['person'],
  methods: {
    redirectLink () {
      window.location = this.person.url``
    }
  }
}
