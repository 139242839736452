import activeresize from '../mixins/activeresize'
import expandcollapse from './../mixins/expandcollapse'

export default () => {
  return {
    mixins: [expandcollapse],
    props: {
      expandtext: {
        type: String,
        default: 'Open All'
      },
      closetext: {
        type: String,
        default: 'Close All'
      }
    },
    data: () => ({
      items: [],
      active: []
    }),
    computed: {
      allAccordionActive () {
        return (this.active.length === 0) ? false : this.active.length === this.items.length
      },
      toggletext () {
        return (this.active.length && this.active.length === this.items.length) ? this.closetext : this.expandtext
      }
    },
    methods: {
      create () {
        let id = this.items.length
        this.items[id] = { id: id }
        return this.items[id]
      },
      toggle (which) {
        const index = this.active.findIndex((item) => item.id === which)

        if (index !== -1) {
          this.active = this.active.filter((item) => item.id !== which)
        } else {
          this.active.push({id: which})
        }
      },
      toggleAll () {
        if (this.active.length < this.items.length) {
          this.active = this.items
          if (!this.viewMoreExpanded()) 
            this.toggleServices()
        } else {
          this.active = []
          if (this.viewMoreExpanded())
            this.toggleServices()
        }
        
      },
      isActive (which) {
        const index = this.active.findIndex((item) => item.id === which)
        return index !== -1
      }
    },
    components: {
      'accordionitem': {
        data: () => ({
          item: {}
        }),
        computed: {
          id () {
            return this.item.id
          }
        },
        methods: {
          isActive () {
            return this.$parent.isActive(this.id)
          },
          toggle () {
            this.$parent.toggle(this.id)
          }
        },
        mounted () {
          if (this.$parent.create) {
            this.item = this.$parent.create()
          }
        },
        mixins: [activeresize]
      }
    }
  }
}
