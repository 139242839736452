import Vue from 'vue'
import search from './../mixins/search'
import KeyContactCard from './KeyContactCard'

Vue.component('PeopleSearchLanding', {
  props: {
    moreoptionslabel: {
      type: String,
      default: 'View More'
    },
    lessoptionslabel: {
      type: String,
      default: 'View Less'
    }
  },
  data: () => ({
    endpoint: '/api/peopleresults',
    filters: {
    },
    inputModels: { // input boxes
      keywordSearch: '',
      keywordServices: '',
      keywordCredentials: ''
    },
    dropdownModels: {
      locations: {
        value: '',
        label: ''
      },
      position: {
        value: '',
        label: ''
      },
      language: {
        value: '',
        label: ''
      }
    },
    moreoptionsactive: false
  }),
  computed: {
    toggleoptionslabel () {
      return (this.moreoptionsactive) ? this.lessoptionslabel : this.moreoptionslabel
    },
    results () {
      return (this.response)
        ? this.response.results
        : undefined
    },
    count () {
      return (this.results)
        ? this.results.length
        : 0
    },
    total () {
      return (this.response)
        ? this.response.totalResults
        : 0
    },
    getAppliedFilters () {
      return this.filterList.filter(this.isFilterPopulated)
    },
    hasAppliedFilters () {
      return (this.getAppliedFilters.length > 0)
    },
    getPopulatedInputModels () {
      return Object.keys(this.inputModels)
        .filter((key) => this.inputModels[key] !== '')
    },
    getPopulatedDropdownModels () {
      return Object.keys(this.dropdownModels)
        .filter((key) => this.dropdownModels[key].value !== '')
    }
  },
  methods: {
    onLetterClick (letter, {isDisabled = false}) {
      if (!isDisabled) {
        this.setFilter('letter', letter)
          .then(this.scrollTo('search-results'))
      }
    },
    isLetterDisabled ({isDisabled = false}) {
      return (isDisabled === true)
    },
    onSubmitSearch () {
      this.setupModelsAsFilters()

      this.pushUrl()
        .then(this._searchIfThereAreFilters())
        .then(this.clearTextboxModels())
        .then(this.scrollTo('search-results'))
    },
    isActiveLetter (letter) {
      return (this.filters.letter && this.filters.letter.value === letter)
    },
    isFilterPopulated (filter) {
      return (filter.value !== '')
    },
    setupModelsAsFilters () {
      this.getPopulatedInputModels.forEach((key) => {
        this.onlySetFilter(key, this.inputModels[key])
      })

      this.getPopulatedDropdownModels.forEach((key) => {
        this.onlySetFilter(key, this.dropdownModels[key].value, this.dropdownModels[key].label)
      })
    },
    getDropdownFilterLabel (vmodel) {
      return (this.dropdownModels[vmodel].value !== '')
        ? this.dropdownModels[vmodel].label
        : false
    },
    clearTextboxModels () {
      this.getPopulatedInputModels.forEach((key) => {
        this.inputModels[key] = ''
      })
    },
    onClearFilter (filter) {
      this.clearFilter(filter)
    },
    toggleMoreOptions () {
      this.moreoptionsactive = !this.moreoptionsactive
    }
  },
  components: {
    KeyContactCard
  },
  mixins: [search]
})
