import { mapGetters } from 'vuex'

import Vue from 'vue'
import axios from 'axios';

Vue.component('ExperienceSearchViewMore', {
    props: {
        initExperienceList: {
            type: Array, 
            default: []
        },
        initialRequestSize: {
            type: Number, 
            default: 5
        },
        requestSize: {
            type: Number, 
            default: 25
        },
        totalResultCount: {
            type: Number,
            default: 0
        },
        abstractCharacterLimit: {
            type: Number,
            default: 200
        }
    },
    data: function() {
        return {
            experienceList: this.initExperienceList,
            endpoint: '/webapi/experiences/relatedexperiences',
            page: 0
        }
    },
    computed: {
        showViewMoreLink: function() {
            return this.experienceList.length < this.totalResultCount;
        },
        ...mapGetters({
            pagesections: 'pagenav/items'
        })        
    },
    methods: {
        viewMore (entityId) {           

            this.page += 1;

            return axios.get(this.endpoint + `?relatedEntityId=${entityId}&page=${this.page}&initialRequestSize=${this.initialRequestSize}&requestSize=${this.requestSize}`)
                .then((response) => {
                    this.experienceList = [...this.experienceList, ...response.data]; 

                    setTimeout(() => 
                        this.pagesections.forEach((pagesection) => {
                            pagesection.resize();
                        }),
                    200)
                })
                .catch((error) => {
                    console.error(error);
                }); 
        },
        truncateAbstract (abstract) {
            var truncatedAbstract = '';
            var activeCharacterCount = 0; 
            const abstractCharacterCount = abstract.split(' ').join('').length;
            const abstractWordArray = abstract.split(' ');
            if (abstractCharacterCount > this.abstractCharacterLimit) {
                for (let i = 0; i < abstractWordArray.length; i++) {
                    activeCharacterCount += abstractWordArray[i].length; 
                    truncatedAbstract += abstractWordArray[i] + ' ';
                    if (activeCharacterCount > this.abstractCharacterLimit) {
                        truncatedAbstract += '...';
                        return truncatedAbstract;
                    }
                }
            } else {
                return abstract;
            }
        }
    }
});  