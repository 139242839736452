import { mapGetters } from 'vuex'

export default () => {
  return {
    props: {
      title: String,
      id: {
        type: String,
        default: 'default'
      },
      offsetpercent: {
        type: Number,
        default: -50
      }
    },
    data: () => ({
      position: 0,
      height: 0,
      wasinview: false
    }),
    computed: {
      inview () {
        return this.windowposition >= this.top &&
          this.windowposition <= this.bottom
      },
      top () {
        return this.position + this.offset
      },
      bottom () {
        return (this.position + this.height) + this.offset
      },
      offsetp () {
        return this.offsetpercent / 100
      },
      offset () {
        const offset = this.height * this.offsetp
        return offset > -500 ? offset : -500
      },
      ...mapGetters({
        windowposition: 'viewport/positionY',
        active: 'pagenav/active',
        pagesections: 'pagenav/items'
      })
    },
    watch: {
      inview (inview) {
        if (!this.wasinview && inview) this.$store.dispatch('pagenav/activate', this.id)
        if (!inview) this.wasinview = false
      }
    },
    methods: {
      isActive (id) {
        return id === this.active
      },
      setElementPosition () {        
        this.position = this.$root.getElementOffset(this.$el).top
      },
      setHeight () {
        this.height = this.$root.getElementHeight(this.$el)
      },
      findSectionElementAndScroll () {
        let section = this.pagesections.find( (item) => item['id'] === this.id)

        this.$root.scrollToAndFocus(section.element)
      },
      checkURLAgainstSection () {        
        let windowURLHash = (window.location.search !== '')
          ? window.location.search.split('?section=')[1]
          : ''

        if (!!windowURLHash && !!this.id && decodeURIComponent(windowURLHash) === this.id) {
          this.$nextTick(() => { this.findSectionElementAndScroll() })
        }
      },
      resize () {
        this.setElementPosition()
        this.setHeight()
      }
    },
    mounted () {
      this.$store.dispatch('pagenav/create', {
        title: this.title,
        id: this.id,
        element: this.$el,
        resize: this.resize
      })
      this.setElementPosition()
      this.setHeight()
      this.checkURLAgainstSection()
    }
  }
}
