import Vue from 'vue'

export default Vue.directive('ScrollDisabled', (el, { value }) => {
  const body = document.getElementsByTagName('body')[0]

  if (value) {
    body.classList.add('page--no-scroll')
  } else {
    body.classList.remove('page--no-scroll')
  }
})
