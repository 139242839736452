import sticky from '../mixins/sticky'
import { isNumber } from './../utils/helpers'
import { mapGetters } from 'vuex'

export default () => {
  return {
    props: {
      offset: {
        type: Number,
        default: 50
      }
    },
    data: () => ({
      parent: null,
      triggers: ['overlay']
    }),
    computed: {
      stuck () {
        return this.enter && this.allow
      },
      allow () {
        return this.viewportready && this.viewportdesktop
      },
      newposition () {
        return this.computedoffset()
      },
      haslimit () {
        return isNumber(this.limit())
      },
      unlimited () {
        const unlimited = this.haslimit && this.limit() > this.bottom()
        return unlimited
      },
      limited () {
        const limited = this.allow && this.haslimit && this.limit() <= this.bottom()
        return limited
      },
      positionstyle () {
        return (this.stuck && this.limited) ? 'absolute' : 'fixed'
      },
      topstyle () {
        return (this.stuck && this.unlimited) ? Math.round(this.newposition) + 'px' : 'auto'
      },
      bottomstyle () {
        return (this.stuck && this.limited) ? 0 + 'px' : 'auto'
      },
      style () {
        return this.stuck ? {
          'position': this.positionstyle,
          'top': this.topstyle,
          'bottom': this.bottomstyle
        } : {
          'top': '',
          'bottom': ''
        }
      }
    },
    methods: {
      limit () {
        let limit = (this.element) ? this.$root.getElementOffset(this.element).bottom : null
        return limit
      },
      height () {
        const height = (this.responder) ? this.$root.getElementHeight(this.responder) : 0
        return height
      },
      bottom () {
        const bottom = (this.scrollposition + this.headerbottom()) + this.height()
        return bottom
      },
      computedoffset () {
        return this.offset + ((isNumber(this.headerheight())) ? this.headerheight() : 0)
      },

      ...mapGetters('header', {
        headerbottom: 'bottom',
        headerheight: 'height'
      })
    },
    mixins: [ sticky ]
  }
}
