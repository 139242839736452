const state = {
  mobilesize: 992,
  smallsize: 768,
  mobile: false,
  small: false,
  width: 0,
  height: 0,
  scrollheight: 0,
  position: {
    y: 0
  },
  ready: false
}

export default state
