import Vue from 'vue'

Vue.component('VideoSlider', {
  props: {

  },
  data: () => ({
    isMounted: true,
    images: [],
    slides: [],
    activeIndex: 0,
    beltHeight: 'inherit',
    slideRange: []

  }),
  mounted () {
   
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
   
  },
  components: {
  }
})
