import { mapGetters } from 'vuex'

export default {
  props: {
    canreset: {
      type: Boolean,
      default: true
    },
    offsetpercent: {
      type: Number,
      default: 0
    },
    startafter: Boolean
  },
  data: () => ({
    id: 0,
    element: null
  }),
  mounted () {
    this.$store.dispatch('scrolltrigger/create', {
      title: this.title
    }).then((id) => {
      this.element = this.$el
      this.id = id
      this.updateOnPosition(this.scrollposition)
    })
  },
  computed: {
    isactive () {
      let fun = this.$store.getters['scrolltrigger/isactive']
      let is = fun(this.id)
      return is
    },
    scrollposition () {
      return this.scrollY
    },
    startposition () {
      return this.$root.getElementOffset(this.element).top
    },
    offsetp () {
      return this.offsetpercent / 100
    },
    offset () {
      return this.startposition * this.offsetp
    },
    ...mapGetters('viewport', {
      scrollY: 'positionY',
      mobile: 'mobile'
    }),
    ...mapGetters('scrolltrigger', {
      items: 'items',
      actives: 'actives'
    })
  },
  watch: {
    scrollposition (position) {
      this.updateOnPosition(position)
    }
  },
  methods: {
    entered () {
      return (this.startafter) ? this.scrollposition + window.innerHeight >= this.startposition + this.offset
      : this.scrollposition >= this.startposition + this.offset
    },
    updateOnPosition (position) {
      if (this.entered()) {
        this.$store.commit('scrolltrigger/activate', this.id)
      } else if (this.canreset) {
        this.$store.commit('scrolltrigger/deactivate', this.id)
      }
    }
  }
}
