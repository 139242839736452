import axios from 'axios'
import { TweenLite } from 'gsap'
import 'gsap/ScrollToPlugin'

const empty = {
  filters: {},
  response: null
}

const LandingPageMixin = ({

  data: () => ({
    filters: empty.filters,
    response: empty.response,
    endpoint: undefined,
    paginationFilterNames: [ 'page' ],
    searchOnLoad: false,
    isSearching: false,
    scrollTime: 1.2
  }),

  mounted () {
    const pathname = window.location.pathname
    this._setupHistoryListener(pathname)
    if (this.searchOnLoad) {
      this.search()
    } else {
      this.replaceUrl()
    }
  },

  destroyed () {
    const pathname = window.location.pathname
    this._removeHistoryListener(pathname)
  },

  computed: {

    filterList () {
      return Object.keys(this.filters)
        .map(key => ({
          key,
          value: this.filters[key].value,
          label: this.filters[key].label
        }))
    },

    hasAnyFilters () {
      return Object.keys(this.filters).length > 0
    },

    nonPaginationFilterList () {
      return this.filterList
        .filter(({ key }) =>
          this.paginationFilterNames.indexOf(key) === -1
        )
    },

    stateToCache () {
      return {
        filters: this.filters,
        response: this.response
      }
    },

    queryString () {
      const filterString = this.filterList
        .map(this._normalizeArray)
        .map(({ key, value }) => `${key}=${encodeURIComponent(value)}`)
        .join('&')

      return filterString
        ? `?${filterString}`
        : ''
    },

    apiUrl () {
      return (!this.endpoint)
        ? undefined
        : (this.endpoint.toLowerCase().indexOf('http') === 0)
          ? [ this.endpoint, this.queryString ].join('')
          : [ window.location.origin, this.endpoint, this.queryString ].join('')
    },

    fullUrl () {
      return [
        window.location.origin,
        window.location.pathname,
        this.queryString
      ].join('')
    }

  },
  methods: {

    getFilter (key) {
      return this.filters[key]
    },

    setFilterAndClearPage (key, value, label = undefined) {
      return this.clearPaginationFilters()
        .then(_ => this.setFilter(key, value, label))
    },

    setFilter (key, value, label = undefined) {
      return this.onlySetFilter(key, value, label)
        .then(this.pushUrl)
        .then(this._searchIfThereAreFilters)
    },

    onlySetFilter (key, value, label = undefined) {
      const filter = label
        ? { value, label }
        : { value, label: value }

      if (value != null) {
        this.$set(this.filters, key, filter)
      } else {
        this.$delete(this.filters, key)
      }
      return Promise.resolve(this.filters[key])
    },

    clearFilter (key) {
      return this.onlyClearFilter(key)
        .then(this._clearResponseIfNoFilters)
        .then(this.pushUrl)
        .then(this._searchIfThereAreFilters)
    },

    onlyClearFilter (key) {
      return Promise.resolve(this.onlySetFilter(key, undefined))
    },

    clearPaginationFilters () {
      return Promise.all(
        this.paginationFilterNames
          .map(this.onlyClearFilter)
      )
    },

    clearAllFilters () {
      return Promise.all(
        Object.keys(this.filters)
          .map(this.onlyClearFilter)
      )
        .then(this.pushUrl)
    },

    clearResponse () {
      this.$set(this, 'response', empty.response)
      return Promise.resolve(this.response)
    },

    replaceUrl () {
      window.history.replaceState(this.stateToCache, null, this.fullUrl)
      return Promise.resolve(this.stateToCache)
    },

    pushUrl () {
      window.history.pushState(this.stateToCache, null, this.fullUrl)
      return Promise.resolve(this.stateToCache)
    },

    // Performing searches
    search ({ onSuccess, onError } = {}) {
      if (this.apiUrl) {
        this.isSearching = true
        return axios.get(this.apiUrl)
          .then(onSuccess || this._onSearchSuccess)
          .catch(onError || this._onSearchError)
          .then(this.replaceUrl)
          .then(state => {
            this.isSearching = false
            return state
          })
      } else {
        const error = `No API endpoint provided, can't perform search.`
        return Promise.reject(error)
      }
    },

    // Scrolling to results
    scrollTo (id = 'results') {
      const error = `Could not scroll, no element has id ${id}`
      return new Promise((resolve, reject) =>
        (document.getElementById(id))
          ? TweenLite.to(window, this.scrollTime, {
            scrollTo: '#' + id,
            offsetY: -200,
            onComplete: resolve
          })
          : reject(error)
      )
    },

    // Internal functions
    _doNothing () {},
    _searchIfThereAreFilters () {
      if (this.hasAnyFilters) {
        return this.search()
      }
    },
    _clearResponseIfNoFilters () {
      if (this.hasAnyFilters === false) {
        return this.clearResponse()
      }
    },
    _normalizeArray: (value) =>
      (value instanceof Array)
        ? value.join(',')
        : value,

    _setStateFromCache (pathname) {
      return ({ state }) => {
        if (window.location.pathname === pathname) {
          this.filters = state.filters
          this.response = state.response
        }
      }
    },

    _setupHistoryListener (pathname) {
      window.addEventListener('popstate', this._setStateFromCache(pathname))
    },

    _removeHistoryListener (pathname) {
      window.removeEventListener('popstate', this._setStateFromCache(pathname))
    },

    _onSearchSuccess ({ data }) {
      const response = data
      this.$set(this, 'response', response)
      return this.response
    },

    _onSearchError (reason) {
      console.error(reason)
      this.response = empty.response
      return reason
    }

  }
})

export default LandingPageMixin
