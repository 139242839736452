import {isBoolean} from './../../../utils/helpers'

export default {
  vimeoApiLoaded: (state, value) => {
    state.vimeoApiLoaded = (isBoolean(value)) ? value : !state.vimeoApiLoaded
  },
  youtubeApiLoaded: (state, value) => {
    state.youtubeApiLoaded = (isBoolean(value)) ? value : !state.youtubeApiLoaded
  },  
  videoUrl: (state, value) => {
    state.videoUrl = value
  }
}
