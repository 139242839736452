import { TweenLite } from 'gsap'
import 'gsap/ScrollToPlugin'

export default () => {

    /**
     * Scroll to function that we attach to the window so the coveo search pages can call without including gsap multiple times
     * @param {string} scrollToSection - Section to scroll to (e.g. '.coveo-results-column')
     */
    const handleScroll = (scrollToSection) => {

      // Don't scroll when we're on the site search modal
      const openModal = document.querySelector('.fullpanel--active')
      if (openModal)
        return

      TweenLite.to(window, 1.2, {
          scrollTo: {
              y: scrollToSection,
              offsetY: 100
          }
      })
    }

    /**
     * Compare function to check if the two arrays are the same thing
     * Only used for an array of strings
     * @param {Array} array1 
     * @param {Array} array2 
     */
    const compare = (array1, array2) => {
      if (array1.length !== array2.length)
        return false

      array1.sort()
      array2.sort()
      for (var i = 0; i < array1.length; i++){
        if (array1[i] !== array2[i]) return false
      }
      return true
    }

    /**
     * Array to compare the length of the items inside (strings)
     * @param {Array} array1 
     * @param {Array} array2 
     */
    const compareLength = (array1, array2) => {
      if (array1.length !== array2.length)
        return false
      
      if (array1.length === 0)
        return false

      for (let i = 0; i < array1.length; i++){
        if (array1[i].length < array2[i].length) return false
      }
      return true
    }

    window.handleScroll = handleScroll
    window.customCompare = compare
    window.customCompareLength = compareLength
}