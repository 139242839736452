import Vue from 'vue'

export default Vue.directive('tabbable-overlay', {
  update (el, {value}, vnode) {
    const $app = vnode.context

    let lastFocusedElement = ''
    let isActiveOverlay = value
    let tabbableElements = Array.prototype.slice.call($app.$el.querySelectorAll('a, button, input, select, textarea, .page [tabindex="0"]'))

    tabbableElements.forEach(element => {
      if (isActiveOverlay && !el.contains(element)) {
        if (element.hasAttribute('tabindex') && element.getAttribute('tabindex') !== '0') {
          element.setAttribute('has-tabindex', true)
        }
        element.setAttribute('tabindex', -1)
      } else {
        (element.hasAttribute('has-tabindex') || element.getAttribute('tabindex') === '0')
          ? element.setAttribute('tabindex', 0)
          : element.removeAttribute('tabindex')
      }
    })

    // Set Aria Tags for Element
    el.setAttribute(
      'aria-hidden',
      (isActiveOverlay) ? 'false' : 'true'
    )
    el.setAttribute(
      'tabindex',
      (isActiveOverlay) ? 0 : -1
    )
    if (isActiveOverlay) {
      lastFocusedElement = document.activeElement
      el.focus()
    } else {
      if (lastFocusedElement !== '') {
        lastFocusedElement.focus()
        lastFocusedElement = ''
      }
    }
  }
})
