import Vue from 'vue'

Vue.component('VideoHero', {
  data: () => ({
    isHovered: false
  }),
  methods: {
    mouseOver () { this.isHovered = true },
    mouseLeave () { this.isHovered = false }
  }
})
