import Vuex from 'vuex'

// Modules
import viewport from './modules/viewport'
import header from './modules/header'
import sticky from './modules/sticky'
import scrolltrigger from './modules/scrolltrigger'
import menupanel from './modules/menupanel'
import searchpanel from './modules/searchpanel'
import pagenav from './modules/pagenav'
import sharemenu from './modules/sharemenu'
import videoPlayer from './modules/videoPlayer'
import emailForm from './modules/emailForm'
import vCardModal from './modules/vCardModal'
// import newsfeed from './modules/newsfeed'

const debug = (thing, props_, lastProp) => {
  const props = props_ || []
  if (props.length === 0) {

  } else {
    var firstProp = props.shift()
    if (thing) {
      debug(thing[firstProp], props, firstProp)
    } else {
      debug(`Could not find property ${lastProp}.`, [])
    }
  }
  return thing
}

export default (Vue) => {
  if (!Vue) return
  Vue.use(Vuex)
  return new Vuex.Store({
    modules: {
      viewport,
      header,
      sticky,
      scrolltrigger,
      menupanel,
      searchpanel,
      pagenav,
      sharemenu,
      videoPlayer,
      emailForm,
      vCardModal
    }
  })
}
