import Vue from 'vue'

function onElementHeightChange (elm, callback) {
  let lastHeight = elm.clientHeight
  let newHeight

  (function run () {
    newHeight = elm.clientHeight
    if (lastHeight !== newHeight) callback()
    lastHeight = newHeight

    if (elm.onElementHeightChangeTimer) clearTimeout(elm.onElementHeightChangeTimer)

    elm.onElementHeightChangeTimer = setTimeout(run, 200)
  })()
}

export default Vue.directive('header', {
  bind (el, {name}, vnode) {
    const $app = vnode.context
    const $store = $app.$store
    const self = Vue.directive(name)
    if (!$store) throw new Error('This plugin requires a Vuex store')
    if (!self) throw new Error('Directive is not defined')
    if (!el) throw new Error('Element is not defined')

    $store.commit('header/setSpecs', self.getSize($app, el))

    window.addEventListener('resize', function () {
      $store.commit('header/setSpecs', self.getSize($app, el))
    })

    onElementHeightChange(document.body, function () {
      $store.commit('header/setSpecs', self.getSize($app, el))
    })

    $app.$root.documentReady(function () {
      $store.commit('header/setSpecs', self.getSize($app, el))
    })
  },
  getSize: function ($app, el) {
    let height = $app.$root.getElementHeight(el)
    let offset = $app.$root.getElementOffset(el)

    return {
      top: offset.top,
      bottom: offset.bottom,
      height: height
    }
  }
})
