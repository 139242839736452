import Vue from 'vue'

function onElementHeightChange (elm, callback) {
  let lastHeight = elm.clientHeight
  let newHeight

  (function run () {
    newHeight = elm.clientHeight
    if (lastHeight !== newHeight) callback()
    lastHeight = newHeight

    if (elm.onElementHeightChangeTimer) clearTimeout(elm.onElementHeightChangeTimer)

    elm.onElementHeightChangeTimer = setTimeout(run, 200)
  })()
}

export default Vue.directive('viewport', {
  bind (el, {name}, vnode) {
    const $app = vnode.context
    const $store = $app.$store
    const self = Vue.directive(name)
    if (!$store) throw new Error('This plugin requires a Vuex store')
    if (!self) throw new Error('Directive is not defined')

    $store.dispatch('viewport/setWindowSize')

    window.addEventListener('resize', function () {
      $store.dispatch('viewport/setWindowSize')
    })

    onElementHeightChange(document.body, function () {
      $store.dispatch('viewport/setWindowSize')
    })

    window.addEventListener('scroll', function () {
      $store.dispatch('viewport/setPosition')
    })

    onElementHeightChange(document.body, function () {
      $store.dispatch('viewport/setWindowSize')
    })

    $app.$root.documentReady(function () {
      $store.dispatch('viewport/setWindowSize')
      $store.dispatch('viewport/ready')
    })
  }
})
