import Vue from 'vue'

export default Vue.directive('first-letter-style', {
  bind (el, {name}, vnode) {
    const self = Vue.directive(name)

    self.tryFirstLetterStyle(el)
  },
  tryFirstLetterStyle: function (el) {
    let firstParagraph = this.checkForFirstParagraph(el)

    if (firstParagraph) {
      this.applyClassStyle(firstParagraph, el)
    }

    if (this.hasBasicTextContent(el)) {
      this.applyClassStyleToText(el)
    }
  },
  checkForFirstParagraph (el) {
    return (el.children.length > 0 && (el.children[0].tagName === 'P' || el.children[0].tagName === 'DIV'))
      ? el.children[0]
      : false
  },
  hasBasicTextContent (el) {
    return (el.children.length === 0 && el.innerHTML.length > 0)
  },
  applyClassStyle (paragraph, el) {
    if (paragraph.innerHTML.charAt(0) === '<') {
      return false
    }

    let firstLetter = paragraph.innerHTML.charAt(0)
    let tempContent = paragraph.innerHTML.slice(1) // Remove first letter
    let contentToPrepend = `<span class="first-letter">${firstLetter}</span>`

    let htmlNodes = Array.prototype.slice.call(el.children)

    if (htmlNodes.length > 1) {
      htmlNodes.shift()
      htmlNodes = htmlNodes.reduce((accumlator, childNode) => {
        return accumlator + childNode.outerHTML
      }, `<p>${contentToPrepend}${tempContent}</p>`)
    } else {
      htmlNodes = `<p>${contentToPrepend}${tempContent}</p>`
    }

    el.innerHTML = htmlNodes
  },
  applyClassStyleToText (el) {
    let firstLetter = el.innerHTML.trim().charAt(0)
    let tempContent = el.innerHTML.trim().slice(1)
    let contentToPrepend = `<span class="first-letter">${firstLetter}</span>`

    el.innerHTML = `<p>${contentToPrepend}${tempContent}</p>`
  }
})
