export default {
  create: (state, payload) => {
    state.items.push(payload)
  },
  activate: (state, id) => {
    let index = state.actives.indexOf(id)
    if (index === -1) {
      state.actives.push(id)
    }
  },
  deactivate: (state, id) => {
    let index = state.actives.indexOf(id)
    if (index > -1) {
      state.actives.splice(index, 1)
    }
  }
}
