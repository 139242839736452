import sticky from '../mixins/sticky'

export default () => {
  return {
    computed: {
      headeractive () {
        return this.isactive
      },
      panelactive () {
        return this.$root.panelactive
      }
    },
    methods: {
      ifCurrent (url) {
        return {
          'nav__link--is-current': (window.location.pathname.indexOf(url) === 0)
        }
      }
    },
    mixins: [ sticky ]
  }
}
