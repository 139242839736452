import search from './../mixins/search'
import Vue from 'vue'

Vue.component('ArticlesSearchLanding', {
  data: () => ({
    mixins: [search],
    endpoint: '/api/insightsresults',
    filters: {
    },
    inputModels: { // input boxes
      keywordSearch: '',
      keywordServices: '',
      keywordProfessionals: ''
    },
    dropdownModels: {
      locations: {
        value: '',
        label: ''
      }
    }
  }),
  computed: {

  },
  methods: {
    
  }
})
