import Vue from 'vue'
import {mapMutations} from 'vuex'

Vue.component('ContactCard', {
  props: ['cardUrl'],
  methods: {
    redirectLink () {
      if (!!this.cardUrl && this.cardUrl != '') {
        window.location = this.cardUrl
    	}
    },
    ...mapMutations({
      isShowing: 'emailForm/isShowing',
      id: 'emailForm/id'
    }),
    showEmailForm (id) {
      this.isShowing(true);
      this.id(id)
    }
  }
})
