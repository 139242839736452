import Vue from 'vue'
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'

Vue.component('VCardModal', {
  props: {
    sitekey: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    vCardModal: {
      messageText: '',
      vCardLink: '',
      isError: false,
      downloadSuccess: false
    },
  }),
  computed: {
    ...mapGetters({
      isVCardShowing: 'vCardModal/isVCardShowing',
      vCardLink: 'vCardModal/vCardLink'
    })
  },
  methods: {
    ...mapMutations({
      setShowing: 'vCardModal/isVCardShowing'
    }),
    setSubmissionResult(submissionSuccess) {
        if(submissionSuccess) {
            this.vCardModal.downloadSuccess = true
        } else {
            this.vCardModal.isError = true
            this.vCardModal.downloadSuccess = false
        }
    },
    close() {
      this.setShowing(false);
    },
    submit() {
        var data = {
            VCardLink: this.vCardLink
          };
        let that = this;
        grecaptcha.ready(function () {
            grecaptcha.execute(that.sitekey, {action: 'submit'}).then(function (token) {
              data.RecaptchaToken= token;
              axios.post('/webapi/vcard/download', data)
                .then(function (response) {
                    if (response.status == 200) {
                        that.setSubmissionResult(true)
                        window.location.href = that.vCardLink
                        that.close()
                    } else {
                        that.setSubmissionResult(false)
                    }
                }, function (error) {
                    that.setSubmissionResult(false)
                });
            });
          });
    }
  }
})
