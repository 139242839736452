import Vue from 'vue'
import { mapGetters } from 'vuex'

Vue.component('Sectionnav', {
  data: () => ({
    links: [],
    mobilelink: ''
  }),
  created () {
    this.mobilelink = this.$router.history.current.path
  },
  computed: {
    ...mapGetters({
      mobile: 'viewport/mobile'
    })
  },
  methods: {
    addLink ({ url, title }) {
      let componentId

      componentId = this.links.push({
        url,
        title
      })

      return componentId - 1
    },
    goToUrl () {
      window.location = this.mobilelink
    }
  },
  components: {
    'sectionnavLink': {
      data: () => ({
        myId: 0
      }),
      props: ['setupLink', 'url', 'title'],
      mounted () {
        this.myId = this.setupLink({
          url: this.url,
          title: this.title
        })
      }
    }
  }
})
