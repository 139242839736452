export default {
  setWindowSize: (state, payload) => {
    state.width = payload.width
    state.height = payload.height
    state.scrollheight = payload.scrollheight
    state.mobile = payload.width < state.mobilesize
    state.small = payload.width < state.smallsize
  },
  setWindowPosition: (state, payload) => {
    if (payload) state.position = payload
  },
  setHeaderHeight: (state, payload) => {
    if (payload) state.headerheight = payload
  },
  setReady: (state, payload) => {
    state.ready = payload
  }
}
