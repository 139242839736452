import Vue from 'vue'
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

Vue.component('VideoCarousel', {
  props: ['modalState'],
  data: () => ({
    currentSlide: 0,
    totalSlides: null,
    swiper: null,
    isAutoplaying: true,
    isHovered: false,
    autoplayDuration: null,
    isMobile: null,
    hasSlider: null,
  }),
  mounted () {
   this.checkForCarousel();
  },
  computed: {
  
  },
  watch: {
    isMobile() {
        if (this.isMobile && this.swiper) {
            // stop autoplay on mobile
            this.swiper.autoplay.stop();
            this.isAutoplaying = false;
        } else {
            //resume autoplay on desktop
            this.swiper.autoplay.start();
            this.isAutoplaying = true;
        }
    },
    modalState() {
        if (!this.modalState) {
            this.playAutoplay();
            this.isAutoplaying = true;
        }
    }
  },
  methods: {
    checkForCarousel() {
        var slides = this.$el.querySelectorAll('.video-carousel-item').length;
        this.totalSlides = slides;
        // console.log(slides)
        if (slides > 1) {
            this.initCarousel();
            this.checkIsMobile();
        }
    },
    initCarousel() {
        this.autoplayDuration = parseInt(this.$el.dataset.slideDurationMs);
        this.swiper = new Swiper(this.$el, {
            // Optional parameters
            loop: false,
            simulateTouch: false,
            preventClicks: false,
            preventClicksPropagation: false,
            shortSwipes: false, 
            longSwipesRatio: 0.1, 
            longSwipesMs: 100,
          
            // If we need pagination
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
          
            slidesPerView: 1,

            // swiper autoplay has weird offset of 1s
            autoplay: {
                delay: ((this.autoplayDuration - 1000) > 0) ? this.autoplayDuration - 1000 : this.autoplayDuration,
                disableOnInteraction: false,
            },
            on: {
                autoplayStart: () => {
                    this.handleTimer();
                },
                autoplayStop: () => {
                    this.$refs.timer.classList.remove('animate');
                },
                init: () => {
                    this.handleInteraction();
                },
                touchEnd: () => {
                    // enable rewind feature on touch
                    let diff = (this.swiper.touches.currentX - this.swiper.touches.startX);
                    if (diff == 0) {
                    } else if (diff < 0) {
                        //swiping left/next
                        if (this.swiper.isEnd) {
                            this.swiper.slideTo(0);
                        } 
                    } else {
                        //swiping right/prev
                        if (this.swiper.isBeginning) {
                            let lastSlide = this.totalSlides - 1;
                            this.swiper.slideTo(lastSlide);
                        }
                    }
                }
            }
          });

          this.swiper.on('slideChange', () => {
            this.currentSlide = this.swiper.realIndex + 1; //1 index
            this.$refs.timer.classList.remove('animate');
            if (this.isAutoplaying && !this.isHovered) {
                setTimeout(() => {
                    this.handleTimer();
                }, 100)
            }
          });

          this.hasSlider = true;
    },

    pauseAutoplay() {
        this.swiper.autoplay.stop();
    },
    playAutoplay() {
        if (!this.isHovered) {
            this.swiper.autoplay.start();
        }
    },
    toggleAutoplay() {
        this.isAutoplaying = !this.isAutoplaying;
        if (this.isAutoplaying) {
            this.playAutoplay();
        } else {
            this.pauseAutoplay();
        }
    },
    checkIsMobile() {
        var mql = window.matchMedia("(max-width: 767px)");
        this.isMobile = mql.matches;
        mql.onchange = (e) => {
            this.isMobile = e.matches;
        }
    },
    handlePlay() {
        this.isAutoplaying = false;
        this.pauseAutoplay();
    },
    handleTimer() {
        let svg = this.$refs.timer.querySelector('svg circle');
        this.$refs.timer.classList.add('animate');
        svg.style.animationDuration = `${this.autoplayDuration}ms`;
    },
    handleInteraction() {
        this.$el.addEventListener('mouseenter', () => {
            this.isHovered = true;
            this.pauseAutoplay();
        })

        this.$el.addEventListener('mouseleave', () => {
            this.isHovered = false;
            if (!this.isAutoplaying) {
                this.pauseAutoplay();
            } else {
                this.playAutoplay();
            }
        })
    },
    handleNext() {
        //manually handle next/prev for rewind feature
        if (this.swiper.isEnd) {
            this.swiper.slideTo(0)
        } else {
            this.swiper.slideNext();
        }
    },
    handlePrev() {
        //manually handle next/prev for rewind feature
        if (this.swiper.isBeginning) {
            // convert from 1 index to 0 index
            let lastSlide = this.totalSlides - 1;
            this.swiper.slideTo(lastSlide);
        } else {
            this.swiper.slidePrev();
        }
    }
  },
  components: {
  }
})
