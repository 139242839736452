import Vue from 'vue';
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

Vue.component('HeroCarousel', {
  props: {},
  data: () => ({
    currentSlide: 0,
    swiper: null,
    isAutoplaying: true,
    autoplayDuration: null,
    bgImgs: [],
    isMobile: null, //1200px
    isSmMobile: null, //768px
    hovered: false,
    hasSlider: null,
    currentBgImg: null,
    navHovered: null,
    swiperIsInit: false,
  }),
  mounted() {
    this.checkForCarousel();
  },
  computed: {
    slideCounterClass() {
      return `hero-carousel--slides-${this.bgImgs.length}`;
    },
  },
  watch: {
    isSmMobile() {
      // console.log('small mobile');
      if (this.isSmMobile) {
        this.isAutoplaying = false;
        if (this.swiper) {
          this.pauseAutoplay();
        }
      } else {
        this.isAutoplaying = true;
        if (this.swiper) {
          this.playAutoplay();
        }
      }
    },
    isMobile() {
      // console.log('is tablet width');
      var slides = this.$el.querySelectorAll('.hero-carousel-item').length;

      this.$nextTick(() => {
        if (this.isMobile && !this.swiperIsInit) {
          if (slides > 1) {
            this.hasSlider = true;
            this.initCarousel();
          }
        }
      });
    },
    hovered() {
      this.$nextTick(() => {
        this.swiper.update();
      });
    },
    navHovered() {
      if (this.navHovered) {
        this.hovered = false;
      }
    },
  },
  methods: {
    checkForCarousel() {
      this.checkIsMobile();

      var slides = this.$el.querySelectorAll('.hero-carousel-item').length;

      if (this.isMobile) {
        if (slides > 1) {
          this.hasSlider = true;
          this.$nextTick(() => {
            this.initCarousel();
            this.pauseAutoplay();
          });
        } else {
          this.initBgImages();
          this.handleHover();
        }
      } else {
        if (slides > 4) {
          this.hasSlider = true;
          this.$nextTick(() => {
            this.initCarousel();
          });
        } else {
          this.initBgImages();
          this.handleHover();
        }
      }
    },
    initCarousel() {
      this.autoplayDuration = parseInt(this.$el.dataset.slideDurationMs);
      this.initBgImages();

      this.swiper = new Swiper(this.$el, {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        // Navigation arrows
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        slidesPerView: 1,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        slideVisibleClass: 'swiper-slide-visible',
        simulateTouch: false,

        // swiper autoplay has weird offset of 1.5s
        autoplay: {
          delay:
            this.autoplayDuration - 1500 > 0
              ? this.autoplayDuration - 1500
              : this.autoplayDuration,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
        breakpoints: {
          480: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 4,
          },
        },
        on: {
          autoplayStart: () => {
            this.handleTimer();
          },
          autoplayStop: () => {
            this.$refs.timer.classList.remove('animate');
          },
          init: () => {
            this.handleHover();
            this.handleInteraction();
          },
          afterInit: () => {
            setTimeout(() => {
              // make sure slides are correct width after everything is loaded
              this.displayHiddenSlides();
              this.swiper.update();
            }, 500);
          },
        },
      });

      this.swiper.on('slideChange', () => {
        this.currentSlide = this.swiper.realIndex;

        this.currentBgImg = this.bgImgs[this.currentSlide] || this.bgImgs[0];
        this.$refs.timer.classList.remove('animate');
        if (this.isAutoplaying && !this.hovered) {
          setTimeout(() => {
            this.handleTimer();
          }, 100);
        }
      });

      if (this.isSmMobile) {
        this.pauseAutoplay();
      }

      this.swiperIsInit = true;
    },
    displayHiddenSlides() {
      setTimeout(() => {
        var wrapper = document.querySelector('.swiper-container');
        wrapper.classList.add('visible');
      }, 10);
    },
    initBgImages() {
      this.bgImgs = [];
      var slides = this.$el.querySelectorAll(
        '.swiper-slide:not(.swiper-slide-duplicate)'
      );
      var slidesArr = Array.prototype.slice.call(slides);

      slidesArr.forEach((el) => {
        var img = el.dataset.bgImg;
        this.bgImgs.push(img);
      });

      this.currentBgImg = this.bgImgs[this.currentSlide] || this.bgImgs[0];
    },
    pauseAutoplay() {
      this.swiper.autoplay.stop();
    },
    playAutoplay() {
      if (!this.hovered) {
        this.swiper.autoplay.start();
      }
    },
    toggleAutoplay() {
      this.isAutoplaying = !this.isAutoplaying;
      if (this.isAutoplaying) {
        this.playAutoplay();
      } else {
        this.pauseAutoplay();
      }
    },
    checkIsMobile() {
      var mql = window.matchMedia('(max-width: 1200px)');
      var mqlSm = window.matchMedia('(max-width: 768px)');
      this.isMobile = mql.matches;
      this.isSmMobile = mqlSm.matches;
      mql.onchange = (e) => {
        this.isMobile = e.matches;
      };
      mqlSm.onchange = (e) => {
        this.isSmMobile = e.matches;
      };
    },
    handleHover() {
      var next = this.$el.querySelector('.swiper-button-next');
      var prev = this.$el.querySelector('.swiper-button-prev');
      var pagination = this.$el.querySelector('.swiper-nav');

      next.addEventListener('mouseenter', () => {
        this.navHovered = true;
      });
      next.addEventListener('mouseleave', () => {
        this.navHovered = false;
      });
      prev.addEventListener('mouseenter', () => {
        this.navHovered = true;
      });
      prev.addEventListener('mouseleave', () => {
        this.navHovered = false;
      });
      pagination.addEventListener('mouseenter', () => {
        this.navHovered = true;
      });
      pagination.addEventListener('mouseleave', () => {
        this.navHovered = false;
      });

      this.$el.addEventListener('mouseover', () => {
        if (!this.navHovered && !this.isMobile) {
          this.hovered = true;
        } else {
          this.hovered = false;
        }
      });
      this.$el.addEventListener('mouseleave', () => {
        this.hovered = false;
      });

      var slides = this.$el.querySelectorAll('.hero-carousel-item');
      slides.forEach((slide) => {
        slide.addEventListener('mouseenter', () => {
          var bgimg = slide.dataset.bgImg;
          this.currentBgImg = bgimg;
        });

        slide.addEventListener('mouseleave', () => {
          this.currentBgImg = this.bgImgs[this.currentSlide] || this.bgImgs[0];
        });
      });
    },
    handleTimer() {
      let svg = this.$refs.timer.querySelector('svg circle');
      this.$refs.timer.classList.add('animate');
      svg.style.animationDuration = `${this.autoplayDuration}ms`;
    },
    handleInteraction() {
      this.$el.addEventListener('mouseenter', () => {
        this.pauseAutoplay();
      });

      this.$el.addEventListener('mouseleave', () => {
        if (!this.isAutoplaying) {
          this.pauseAutoplay();
        } else {
          if (!this.isSmMobile) {
            this.playAutoplay();
          }
        }
      });
    },
  },
  components: {},
});
