import Vue from 'vue'

export default Vue.directive('animation-delay', {
  bind (el, {value, arg}, vnode) {
    if (value.length <= 0) {
      throw Error('Need Class Tile Classes Defined')
    }

    let animationDelay = (typeof arg === 'number') ? arg : 500

    let tiles = Array.prototype.slice.call(el.querySelectorAll(`.${value}`))

    tiles.forEach((tile, index) => {
      tile.style.transitionDelay = animationDelay * index + 'ms'
    })
  }
})
