import { mapGetters } from 'vuex'

export default () => {
  return {
    props: {
      scrolltosection: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters({
        mobile: 'viewport/mobile',
        pagesections: 'pagenav/items',
        active: 'pagenav/active'
      })
    },
    methods: {
      isActive (id) {
        return id === this.active
      },
      selectSection (id) {
        this.$store.dispatch('pagenav/activate', id).then(date => {
          let section = this.pagesections.find((item) => item['id'] === id)

          if (this.scrolltosection) this.$root.scrollToAndFocus(section.element)
          this.$root.updateSectionHistory(id)
        })
      }
    }
  }
}
