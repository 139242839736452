export default () => { 
var headerElement = document.getElementsByTagName('header')[0]

window.stickyheader = {
  element: headerElement,
  position: headerElement.offsetTop,
  expanded: false
}

var handleHeaderScroll = function () {
  if (window.scrollY > window.stickyheader.position && !window.stickyheader.expanded) {
    window.stickyheader.element.classList.add('header--active')
    window.stickyheader.expanded = true
  } else if (window.scrollY <= window.stickyheader.position && window.stickyheader.expanded) {
    window.stickyheader.element.classList.remove('header--active')
    window.stickyheader.expanded = false
  }
}

window.addEventListener('scroll', handleHeaderScroll)
}