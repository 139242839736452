import Vue from 'vue'
import axios from 'axios'

Vue.component('LanguagePrompt', {
  data: () => ({
    active: true,
    animate: true,
    hide: false
  }),
  methods: {
    accept (url) {
        window.location.href = url
    },
    decline () {
        this.active = false
        this.hide = true
    },
    never () {
        axios.post(`/webapi/cookies/cookie?name=${encodeURIComponent('website#language_prompt')}&value=never&months=1`)
            .then(console.log)
            .catch(console.error)
        this.active = false
        this.hide - true
    }
  }
})
