export default () => {
    const backgroundVideoButton = document.getElementById('homepage-background-video')
    const backgroundVideoPlayer = document.getElementById('homepage-background-video-player')
    
    if (!backgroundVideoButton || !backgroundVideoPlayer)
        return

    const dataPauseText = backgroundVideoButton.getAttribute('data-pause-text') 
    const dataPlayText = backgroundVideoButton.getAttribute('data-play-text')
    
    const pauseBackgroundText = dataPauseText ? dataPauseText : 'Pause'
    const playBackgroundText = dataPlayText ? dataPlayText : 'Play'

    // Set true since video should autoplay initially
    let isBackgroundVideoPlaying = true
    
    backgroundVideoButton.addEventListener('click', () => {
        if (isBackgroundVideoPlaying) {
            backgroundVideoPlayer.pause()
            backgroundVideoButton.innerText = playBackgroundText
        }
        else {
            backgroundVideoPlayer.play()
            backgroundVideoButton.innerText = pauseBackgroundText
        }
        
        isBackgroundVideoPlaying = !isBackgroundVideoPlaying
    })
}