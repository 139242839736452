import { isBoolean } from './../../../utils/helpers'

export default {
  toggleActive: (state, value) => {
    state.active = (isBoolean(value)) ? value : !state.active
  },
  updateKeyword: (state, value) => {
    state.keyword = value
  }
}
