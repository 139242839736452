const isBoolean = (value) =>
  (value === true || value === false)
    ? true
    : false

const isNumber = (value) =>
  (typeof value === 'number')

const isNil = (value) => value == null

const isUndefined = (value) => value === undefined

// Fisher Yates Style
const shuffle = (arr) => {
  let movingLength = arr.length
  let temp
  let index

  // While there remain elements to shuffle…
  while (movingLength) {
    // Pick a remaining element…
    index = Math.floor(Math.random() * movingLength--)

    // And swap it with the current element.
    temp = arr[movingLength] // grab last value in array
    arr[movingLength] = arr[index] // placed picked value at the end, our new shuffled array
    arr[index] = temp
  }
  return arr
}

const range = (numberOfItems) => {
  return [...new Array(numberOfItems)].map((item, index) => index)
}

export {
  isBoolean,
  isNumber,
  isNil,
  isUndefined,
  shuffle,
  range
}
