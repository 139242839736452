import Vue from 'vue'

export default Vue.directive('sidebartrigger', {
  bind (el, {name, value}, vnode) {
    const $app = vnode.context
    const $store = $app.$store
    const self = Vue.directive(name)
    if (!$store) throw new Error('This plugin requires a Vuex store')
    if (!self) throw new Error('Directive is not defined')
    if (!el) throw new Error('Element is not defined')

    let payload = (typeof value === 'string') ? {
      id: value,
      el: el
    } : {
      el: el
    }

    $store.dispatch('sticky/createTrigger', payload)
  }
})
