import { mapGetters } from 'vuex'

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }},
  data: function () {
    return {
      baseheight: 0,
      minheight: 0
    }
  },
  watch: {
    viewportWidth: function () {
      this.$nextTick(function () {
        this.baseheight = this.getHeight()
      })
    }
  },
  computed: {
    ...mapGetters({
      viewportWidth: 'viewport/width'
    }),
    heightstyle () {
      let style = (this.isActive()) ? this.baseheight + 'px' : this.minheight + 'px'
      return style
    },
    heightEl () {
      return this.$refs['heightelement']
    }
  },
  updated: function () {
    this.$nextTick(function () {
      this.baseheight = this.getHeight()
    })
  },
  methods: {
    isActive () {
      return false
    },
    getHeight () {
      let height = null
      if (this.heightEl) {
        height = (this.heightEl.clientHeight) ? this.heightEl.clientHeight : this.heightEl.offsetHeight
      }
      return height
    }
  }
}
