import scrolltrigger from '../mixins/scrolltrigger'

export default () => {
  return {
    props: ['rando'],
    data: () => ({
      childdelay: 500
    }),
    methods: {
      childDelay (i) {
        return (this.childdelay * i) + 'ms'
      }
    },
    mixins: [ scrolltrigger ]
  }
}