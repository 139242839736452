import Vue from 'vue'

Vue.component('KeywordSearchFilter', {
  data: () => ({
    keywordSearch: ''
  }),
  computed: {
    encodedSearchTerm () {
      return window.encodeURIComponent(this.keywordSearch)
    }
  },
  methods: {
    goToSearchResults () {
      if (this.keywordSearch.length > 0) {
        let searchUrl = [window.location.origin, window.location.pathname.slice(1) + '-results'].join('/')

        // Redirect
        window.location = `${searchUrl}?keywordSearch=${this.encodedSearchTerm}`
      }
    },
    onSubmitInput (inputValue) {
      this.keywordSearch = inputValue

      this.onSubmitSearch()
    },
    onSubmitSearch () {
      this.goToSearchResults()
    }
  }
})
