import Vue from 'vue'
import {mapGetters} from 'vuex'

Vue.component('ArticleTabs', {
  props: ['navigation', 'tabPanels'],
  data: () => ({
    activeTab: 0,
    activeTabLabel: '',
    selected: 0,
    classFadedOut: false,
    classAnimateGrid: true,
    classFadedInNav: false,
    childdelay: 500
  }),
  computed: {
    ...mapGetters({
      mobile: 'viewport/mobile'
    }),
    activeCardList () {
      return this.tabPanels[this.activeTab].list
    },
    viewAllOption () {
      return (this.tabPanels[this.activeTab].viewAll)
        ? this.tabPanels[this.activeTab].viewAll
        : false
    }
  },
  created () {
    this.fetchData()
  },
  mounted () {
    this.populateDefaultLabel()
    this.classFadedInNav = true;
  },
  watch: {
    '$route': function () {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      if (this.$route.query && this.$route.query.tab) {
        const currentTabLabel = this.$route.query.tab
        const currentTabIndex = this.navigation.findIndex(navItem => navItem.title === currentTabLabel)
        if (currentTabIndex > -1)
          this.changeTab(currentTabIndex, currentTabLabel)
      }
    },
    populateDefaultLabel () {
      this.activeTabLabel = this.navigation[0].title
      this.$router.push("?tab=" + this.activeTabLabel)
    },
    isActive (givenIndex) {
      return this.activeTab === givenIndex
    },
    fadeTabIn (newIndex) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.classFadedOut = false
          this.classAnimateGrid = true
          resolve()
        }, 100)
      })
    },
    fadeOutContent () {
      return new Promise((resolve, reject) => {
        this.classFadedOut = true
        setTimeout(() => {
          this.classAnimateGrid = false
          resolve()
        }, 500)
      })
    },
    setTabContent (givenIndex, label) {
      this.activeTab = givenIndex
      this.activeTabLabel = label
    },
    changeTab (newIndex, activeLabel) {      
      if(!Number.isInteger(newIndex))  //AM-11735 index or url
      {
        window.open(newIndex, '_self');
        return;
      }
      if (newIndex !== this.activeTab) {
        this.fadeOutContent()
          .then(() => {
            this.$router.push("?tab=" + activeLabel)
            this.setTabContent(newIndex, activeLabel)
            this.$nextTick(this.fadeTabIn)
          })
      }
    },
    childDelay (i) {
      return (this.childdelay * i) + 'ms'
    }
  }
})
